.info{
  color: blue;
  font-size: 1.5em;
  float: right;
}
.error{
  color: red;
  font-size: 1.5em;
  float: right;
}
.blog{
  border: 1px solid;
  /* padding: 0.1em; */
}