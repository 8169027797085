.burger-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  background-color: aliceblue;
  color: #fff;
}

.burger-icon {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 40px;
  height: 30px;
  cursor: pointer;
}

.burger-icon span {
  display: block;
  width: 30px;
  height: 3px;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
}

.line {
  width: 30px;
  height: 4px;
  background-color: #333;
  transition: transform 0.3s ease-in-out;
}

.menucontainer {
  position: fixed; /* Hacer que la capa cubra toda la pantalla */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.37);  */
  background-color:rgba(40,57,101,.9); /* Color semi-transparente (negro con 50% de opacidad) */
  z-index: 10; /* Asegurarse de que la capa esté por encima de todo */
  display: none;
  /* overflow: hidden; */
}

.menu.active {
  display: block !important; /* Mostrar la capa cuando el menú está activo */
}
.menucontainer.active {
  z-index: 10;
  display: block !important; /* Mostrar la capa cuando el menú está activo */
}

.menu {
  z-index: 11;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: aliceblue;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.menu-button {
  display: block;
  padding: 10px 20px;
  margin: 5px;
  font-size: 16px;
  text-decoration: none;
  color: #333;
  border: 1px solid #333;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.menu-button:hover {
  background-color: #333;
  color: #fff;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu ul li {
  padding: 15px;
}

.menu ul li a {
  text-decoration: none;
  color: #fff;
}

.menu.active {
  /* height: 200px; */
  /* height: auto;  */
  height: 100%; /* Ajusta la altura deseada del menú desplegable */
}


/* CSS */
.button-52 {
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}


.button-52:after {
  content: "";
  /* background-color: #ffe54c; */
  background-color: #fff;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.button-52:hover:after {
  top: 0px;
  left: 0px;
}

@media (min-width: 768px) {
  .button-52 {
    padding: 13px 50px 13px;
  }
}