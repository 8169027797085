.output {
  justify-content: center;
  align-content: start;
  padding-top: 1rem;
}

.ac-card {
  position: relative;
  /* font-family: 'Dancing Script', cursive; */
  display: grid;
  /* grid-template-columns: 100px 1fr 50px; */
  grid-template-columns: 40px 1fr 50px;
  background-color: #fff;
  width: 350px;
  /* height: 250px; */
  margin-bottom: 1rem;
  
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0,0,0, 0.5);
}
.ac-card-operation{
  position: relative;
  /* font-family: 'Dancing Script', cursive; */
  display: grid;
  /* grid-template-columns: 173px 1fr 50px; */
  background-color: #fff;
  width: 350px;
  /* height: 200px; */
  margin-bottom: 1rem;
  
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0,0,0, 0.5);
}

.ac-card #logoImage, .ac-card-operation #logoImage {
  position: absolute;
  top: 10px; /* Ajusta la distancia desde la parte superior de la pantalla */
  right: 0px;
}

.ac-icon {
  width: 40px;
  margin-top: 10px;
}

.ac-card-image {
  /* height: 90px; */
  height: 0px;
  /* width: 77.5px; */
  width: 0px;
  margin: 10px 0 0 15px;
  /* border: 3px solid #006341; */
  /* border: 3px solid aliceblue; */
  border: 3px aliceblue;
  
}

.ac-card-info {
  font-size: 1.15rem;
  margin: 0;
}
.ac-card-info span {
  font-size: .9rem;
}

.ac-card-info p {
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 1.3;
}

.ac-card-footer {
  display: grid;
  align-content: center;
  grid-column: 1/-1;
  align-self: end;
  height: 56.5px;
  /* background-color: #006341;  */
  background-color: aliceblue;
}

.ac-logo {
  width: 125px;
  margin-left: 10px;
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.nombre-op {
  margin-left: 1em;
}
.nombre-ficha {
  /* color: red; */
  font-weight: 100;
  /* margin-left: 1em; */
}

.precio-ficha {
  position: absolute;
  right: 50px;
  bottom: 70px;
}

.linea-telefono {
  margin-bottom: 0px;
}
.button-52-telephones {
  font-size: 7px;
  font-weight: 10;
  letter-spacing: 1px;
  padding: 7px 10px 7px 1px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: .5em;
}

.clase-linea-ficha {
  font-size: 14px;
}
.clase-linea-ficha-margen {
  float: right;
  margin-left: 10px;
}

.detalle-linea-ficha {
  font-size: 14px;
  margin-left: 1em;
}
