/* body {
  margin: 0;
  padding: 0;
} */
body {
  color:#292c3b;
  background-color: aliceblue;
  margin: 0;
  padding: 0;
  /* font-family: Arial, sans-serif; */
  /* font-family: Arial, 'Dancing Script', cursive; */
  font:600 16px/18px 'Open Sans', 'Dancing Script', cursive;
}

.header {
  /* background-color: #f2f2f2; */
  /* background-color: #ffffff; */
  /* padding: 20px; */
}

/* .sidebar {
  background-color: #868484;
  color: #fff;
  width: 200px;
  height: 100vh;
  float: left;
} */

/* ul.horizontal-list {
  list-style: none;
  padding: 0;
  margin: 0;
} */
ul.horizontal-list li {
  /* display: inline;
  margin-right: 20px; */
}

.content {
  padding: 20px;
  /* margin-left: 200px; Ancho de la barra lateral */
  background-color: #fff;
  height: 100%;
}

#logoImage {
  width: 40px; /* Ajusta el ancho de la imagen */
  height: auto; /* Mantiene la proporción de aspecto */
  margin-right: 20px;
}

.titulo-logo {
  position: relative;
  top: -20px;
  left: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

h1, h2 {
  font-family: 'Dancing Script', cursive;
  font-size: 50px;
  font-weight: 700;
  color: #333;
  text-align: center;
}
h3 {
  font-family: 'Dancing Script', cursive;
  margin-top: 15px;
  font-size: 35px;
  font-weight: 700;
  color: #333;
  text-align: center;
}
h4 {
  font-family: 'Dancing Script', cursive;
  font-size: 30px;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

h5, h6 {
  font-family: 'Dancing Script', cursive;
  font-size: 25px;
  font-weight: 700;
  color: #333;
  text-align: center;
}

.button{
	/* width:100%;
	color:#fff;
	display:block;
	border:none;
	padding:15px 20px;
	border-radius:25px;
	background:#1161ee; */
}

.student-row, .operation-row {
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  /* position: relative; */
  z-index: 0;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.disabled {
  color: rgb(129, 128, 128);
  border: 1px solid rgb(129, 128, 128);
}
.deuda {
  color: rgb(180, 24, 24);
  border: 1px solid rgb(180, 24, 24);
}
.ac-card-info .disabled {
  color: rgb(129, 128, 128);
  border: 0px;

  /* border: 1px solid rgb(129, 128, 128); */
}

.student-row span {
  /* margin-left: 1em; */
}
span {
  margin-left: 1em;
}

.student-row #pagoPendiente {
  /* color: rgb(122, 5, 5); */
}
.pagoPendiente {
  font-weight: bold;
  color: rgb(122, 5, 5);
  position: absolute;
  right: 40px;
}
.pagoRealizado {
  font-weight: bold;
  color: rgb(6, 165, 19);
  position: absolute;
  right: 40px;
}
.pagoPendiente-ficha {
  float: right;
  margin-left: 200px;
  color: rgb(122, 5, 5);
}
.pagoRealizado-ficha {
  float: right;
  margin-left: 200px;
  color: rgb(6, 165, 19);
}
.importe-mensual {
  float: right;
  margin-left: 20px;
}

.telephones-list {
  /* border: 1px, solid, black; */
}

.student-row #estudiante-inactivo {

}

.button-return-52 {
  /* position: fixed; */
  position: absolute;
  /* z-index: 0; */
  top: 18px; /* Ajusta la distancia desde la parte superior de la pantalla */
  left: 10px; /* Ajusta la distancia desde la parte izquierda de la pantalla */
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 2px 15px 2px;
  outline: 0;
  border: 1px solid rgb(95, 95, 95);
  color: #575757;
  cursor: pointer;
  /* position: relative; */
  /* background-color: rgba(255, 255, 255, 0); */
  background-color: rgb(255, 255, 255);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button-return-52::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-top: 6px solid transparent; /* Tamaño de la flecha */
  border-bottom: 6px solid transparent; /* Tamaño de la flecha */
  border-right: 6px solid #333; /* Color de la flecha */
}

.logout-button {
  /* position: fixed; */
  /* z-index: 0; */
  /* top: 10px;  */
  /* left: 10px; */
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 2px 6px 2px;
  outline: 0;
  border: 1px solid rgb(95, 95, 95);
  cursor: pointer;
  /* position: relative; */
  /* background-color: rgba(255, 255, 255, 0); */
  background-color: rgb(255, 255, 255);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.login-row {
  position: absolute;
  top: 8px; /* Ajusta la distancia desde la parte superior de la pantalla */
  right: 90px; /* Ajusta la distancia desde la parte izquierda de la pantalla */
  margin-left: 1em;
  font-size: .7em;
}

.button-52-despliegue-filtros-ops {
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 7px 9px 7px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button-52-formulario {
  left: 13em;
  margin-top: 25px;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 7px 9px 7px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button-52-formulario-crear-op {
  right: 3em;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 7px 9px 7px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.boton-pagado {
  position: relative;
  left: 1.5em;
}
.button-52-telephone {
  /* margin-top: 25px; */
  border-radius: 2px;
  font-size: 11px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 3px 9px 3px;
  outline: 0;
  border: 1px solid rgb(118, 118, 118);
  color: rgb(97, 97, 97);
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.telephoneadded {
  font-size: medium;
  margin-top: 2px;
  margin-bottom: 2px;
}


.cuadro-opciones-filtro-op {
  display: flex;
}
.cuadro-opciones-filtro-op-group {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 5px; */
  margin-left: 20px;
}
.cuadro-opciones-filtro-op-group input {
  margin-top: 0px;
}

.cuadro-opciones-crear-op-group {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 5px; */
  margin-left: 20px;
  width: 300px;
}

.cuadro-opciones-filtro-op input[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}
.cuadro-opciones-filtro-op label {
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 30px;
  background: grey;
  display: block;
  border-radius: 30px;
  position: relative;
}
.etiqueta-estados-nuevo-alumno{
  margin-top: 5px;
  margin-left: 10px !important;
}
.cuadro-opciones-filtro-op span {
  font-size: 14px;
  margin-left: 0px;
}
.cuadro-opciones-filtro-op label:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 5px;
  width: 27px;
  height: 27px;
  background: #fff;
  border-radius: 27px;
  transition: 0.3s;
}
.cuadro-opciones-filtro-op input:checked + label {
  /* background: #bada55; */
  background: rgb(159, 202, 240);
}
.cuadro-opciones-filtro-op input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}
.cuadro-opciones-filtro-op label:active:after {
  width: 39px;
}

.select-wrapper {
  /* width: 200px; */
  /* height: 150px; */
  max-height: 200px;
  overflow-y: auto !important; 
}
.button-52-select {
  /* overflow-y: auto !important;  */
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 8px 7px 8px;
  margin-left: 25px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button-52-select-dias {
  margin-top: 0px !important;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 4px 4px 4px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.formulario-group select {
  margin-top: 20px;
}

.input-descuento-general {
  width: 4em;
  margin-left: 0.8em;
}
.input-precio-op {
  width: 4em;
  margin-left: 0.8em;
  height: 2em;
  padding: 8px 7px 8px;
  border: solid black 1px;
}

/* Estilos para la tabla */
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Estilos para las celdas de la primera columna */
td:first-child {
  background-color: #e6f7ff;
  font-weight: bold;
}

.calendario-mes-operacion {
  margin-left: 2em;
 
  font-size: 14px;
  /* font-weight: 200; */
  /* letter-spacing: 1px; */
  padding: 8px 0px 8px;
  margin-left: 25px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  /* position: relative; */
  /* background-color: rgba(0, 0, 0, 0); */
  /* user-select: none; */
  /* -webkit-user-select: none; */
  /* touch-action: manipulation; */
}

/* FACTURA */
.factura {
  border: 2px solid #ccc;
  padding: 20px;
  width: 300px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 30px auto;
  /* margin-top: 20px; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.factura .item {
  margin-bottom: 10px;
}

.factura .item:last-child {
  font-weight: bold;
  border-top: 2px solid #999;
  padding-top: 10px;
  margin-top: 10px;
}

.factura .total {
  text-align: right;
}

.factura .total span {
  font-weight: bold;
  color: #e74c3c;
}

/* Opcional: Estilos para resaltar el total */
.factura .total span.highlight {
  background-color: #e74c3c;
  color: white;
  padding: 2px 5px;
  border-radius: 4px;
}

/* SUBTOTAL */
.factura .subtotal {
  text-align: right;
  margin-bottom: .5em;
}

.factura .subtotal span {
  font-weight: bold;
  margin-right: .5em;
  /* color: #8c3ce7; */
}
.factura .subtotal span.subhighlight {
  /* background-color: white; */
  color: #e74c3c;
  padding: 2px 5px;
  border-radius: 4px;
}

.mensualidad-clase-crear-op {
  position: absolute;
  right: 80px;
}

.total-factura {
  text-align:end;
  margin: 2em;
}

/* TEXTAREA FORM CREAR OPERATION */
.descripcion {
  /* border: 2px solid #ccc; */
  /* padding: 20px; */
  width: 300px;
  background-color: white;
  margin: 30px auto;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
}

.descripcion label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-descripcion {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.input-descripcion {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: white;
}

.precio-from-crear-op {
  margin-left: 4em;
}

/* Propiedades de la cortina de errores */
.cortina-error {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mensaje-error-cortina {
  color: aliceblue;
  margin-top: 10em;
  text-align: center;
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #bb030393;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Spinner */
.container-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  /* background-color: #f0f0f0; */
}

.spinner {
  border: 4px solid rgba(184, 184, 184, 0.308);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Boton de pagar */
.button-pagar {
  margin-bottom: 1em;
}

.button-borrar {
  margin-top: 2em;
  margin-bottom: 1em;
  padding: 10px !important;
}

.boton-editar-estudiante {
  margin-right: .5em;
}

.lista-notas {
  border: 2px solid #ccc;
  padding: 20px;
  /* width: 350px; */
  width: auto;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 10px auto;
  /* margin-top: 20px; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);  
}

u.nota-fila{
  color:#575757;
  font-size: 14px;
  margin: 5px;
  word-wrap: break-word;
  /* display:initial; */
}
p.nota-fila {
  color:#575757;
  font-size: 16px;
  margin: 5px;
  word-wrap: break-word;
  /* display:initial; */
}
.nota-bloque {
  margin-bottom: 2em;
  line-height: 1.5;
}

.input-descuento-masa {
  margin-top: 10px;
  margin-left: 40px;
  width: 50px;
  height: 35px;
  font-size: 18px;
  text-align: center;
}